<template>
	<div class="tool-page">
		<Introduce
			:title="$t('tools.technical-views.title')"
			:titleBefore="$t('tools.technical-views.title-sm1')"
			:titleSm="$t('tools.technical-views.title-sm2')"
		>
			<template #content>
				<div class="content-slot">
					{{ $t('tools.technical-views.content') }}
				</div>
			</template>
			<template #button>
				<b-button variant="primary" class="custom-btn" @click="goToLogin()">
          <div class="text-white-button button-large text-nowrap d-flex justify-content-center">
            {{ $t("tools.economic-calendar.start-btn") }}
          </div>
				</b-button>
			</template>
			<template #itemRight>
				<div class="layout-right">
					<iframe
						class="youtube"
						src="https://www.youtube.com/embed/SfTCZB5pbT0"
						title="TC Technical Views - Plan your trades with key levels from Trading Central"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin" />
				</div>
			</template>
		</Introduce>
		<div class="title title-tool-page">
			{{ $t("tools.technical-views.begin1") }}
			<span class="text-primary">
				{{ $t("tools.technical-views.begin2") }}
			</span>
		</div>
		<!-- row 1 -->
		<div class="content-item">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.technical-views.content-item-title1") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.technical-views.content-item-text1") }}
					<div class="hinde-sm enter-paragraphs"></div>
					{{ $t("tools.technical-views.content-item-text2") }}
					<span class="bold">
						{{ $t("tools.technical-views.content-item-text3") }}
					</span>
					{{ $t("tools.technical-views.content-item-text4") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/technical.png" alt="">
		</div>
		<!-- row 2 -->
		<div class="content-item">
			<img src="@/assets/toolImg/technical1.png" alt="" class="hinde-md">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.technical-views.content-item-title2") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.technical-views.content-item-text5") }}
					<span class="bold">
						{{ $t("tools.technical-views.content-item-text6") }}
					</span>
					{{ $t("tools.technical-views.content-item-text7") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/technical1.png" alt="" class="hinde-sm">
		</div>
		<!-- row 3 -->
		<div class="content-item">
			<div class="content-item__info">
				<div class="content-item__info__title">
					{{ $t("tools.technical-views.content-item-title3") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.technical-views.content-item-text8") }}
					<span class="bold">
						{{ $t("tools.technical-views.content-item-text9") }}
					</span>
					{{ $t("tools.technical-views.content-item-text10") }}
					<div class="enter-paragraphs"></div>
					{{ $t("tools.technical-views.content-item-text11") }}
					<span class="bold">
						{{ $t("tools.technical-views.content-item-text12") }}
					</span>
					{{ $t("tools.technical-views.content-item-text13") }}
				</div>
			</div>
			<img src="@/assets/toolImg/technical2.png" alt="">
		</div>
		<!-- row 4 -->
		<div class="content-item">
			<img src="@/assets/toolImg/calendar3.png" alt="" class="hinde-md">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.technical-views.content-item-title4") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.technical-views.content-item-text14") }}
					<span class="bold">
						{{ $t("tools.technical-views.content-item-text15") }}
					</span>
					{{ $t("tools.technical-views.content-item-text16") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/calendar3.png" alt="" class="hinde-sm">
		</div>
		<div class="btn-bottom">
			<b-button variant="primary" class="custom-btn" @click="goToLogin()">
				<div class="text-white-button button-large text-nowrap d-flex justify-content-center">
					{{ $t("tools.economic-calendar.start-btn") }}
				</div>
			</b-button>
		</div>
	</div>
</template>

<script>
import Introduce from "@/components/tool/Introduce.vue";
import { mapGetters } from 'vuex';
export default {
	components: {
		Introduce,
	},
	computed: {
    ...mapGetters({
      portal_url: 'portal_url'
    })
  },
	data() {
		return {
		}
	},
	mounted() {

	},
	watch: {

	},
	methods: {
		goToLogin() {
      if (this.portal_url) window.open(`${this.portal_url}/register?lang=${this.$i18n.locale}`, '_blank');
    },
	},
	metaInfo: {
		title: "NOZAX: Technical Views",
		meta: [
			{
				name: "description",
				content: "" ,
			},
		],
	},
};
</script>
<style lang="scss" scoped>
.content-slot {
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 24px;
	color: #616A70;
	@media (max-width: 1160px) {
    width: 424px;
		font-size: 20px;
  }
	@media (max-width: 880px) {
    width: 100%;
		font-size: 18px;
  }
}
.title {
	max-width: 421px;
	@media (max-width: 880px) {
		margin-top: 64px;
  }
}
.content-item {
	margin-top: 96px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__info {
		width: 460px;
		&__title {
			font-size: 28px;
			font-weight: 500;
			font-family: Roboto;
			letter-spacing: -0.28px;
			width: 366px;
			color: #484C57;
		}
		&__text {
			font-size: 18px;
			font-weight: 400;
			margin-top: 24px;
			color: #616A70;
			.enter-paragraphs {
				height: 10px;
			}
			.bold {
				font-weight: 700;
			}
		}
	}
	&:last-child {
		margin-bottom: 54px;
	}
	@media (max-width: 1160px) {
    &__info {
			width: 333px;
			&__text {
				font-size: 16px;
			}
		}
		img {
			width: 420px;
		}
  }
	@media (max-width: 880px) {
		display: block;
		margin-top: 56px;
    &__info {
			width: 100%;
			&__title {
				font-size: 20px;
				letter-spacing: 0;
				width: 70%;
				min-width: 260px;
			}
			&__text {
				font-size: 18px;
				.bold {
					font-weight: 400;
				}
			}
		}
		img {
			margin-top: 32px;
			width: 100%;
		}
  }
}
.layout-right {
	margin-top: 80px;
	background-image: url(../../assets/toolImg/backgroundVideo.png);
	background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
	width: 560px;
	height: 347px;
	display: flex;
	justify-content: center;
	align-items: center;
	.youtube {
		width: 91%;
    height: 83%;
	}
	@media (max-width: 1160px) {
    position: absolute;
		right: 0;
		margin-top: 160px;
		width: 440px;
		height: 305px;
		.youtube {
			width: 90%;
    	height: 75%;
		}
  }
	@media (max-width: 880px) {
    position: relative;
		margin-top: 67px;
		width: 100%;
		height: auto;
		aspect-ratio: 1.5;
		.youtube {
			width: 90%;
      height: 75%;
		}
  }
}
.btn-bottom {
	margin-top: 48px;
	margin-bottom: 100px;
	text-align: center;
}
@media (max-width: 1160px) {
	.btn-bottom {
		margin-bottom: 48px;
	}
}
@media (max-width: 880px) {
	.btn-bottom {
		margin-bottom: 57px;
	}
}

</style>